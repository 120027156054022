// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tn8R4EO9BCGLijb8ttaD{height:600px;overflow-y:scroll}", "",{"version":3,"sources":["webpack://./../next-react/src/components/bar/FormPicker/styles.scss"],"names":[],"mappings":"AAEE,sBACE,YAAA,CACA,iBAAA","sourcesContent":["$theme: next;\n.FormPicker {\n  &_forms {\n    height: 600px;\n    overflow-y: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormPicker_forms": "tn8R4EO9BCGLijb8ttaD"
};
export default ___CSS_LOADER_EXPORT___;
