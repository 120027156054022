// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fphEpnRG72kI5COFqD_Q{cursor:pointer}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/ImageGallery/styles.scss"],"names":[],"mappings":"AAEE,sBACE,cAAA","sourcesContent":["$theme: next;\n.imageGalleryGrid {\n  &_image {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageGalleryGrid_image": "fphEpnRG72kI5COFqD_Q"
};
export default ___CSS_LOADER_EXPORT___;
