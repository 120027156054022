// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MH8yCp8sLQ9G8G2lNA02{position:relative;width:100%;height:100%;min-height:200px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/charts/D3Component/styles.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":["$theme: next;\n.main {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  min-height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "MH8yCp8sLQ9G8G2lNA02"
};
export default ___CSS_LOADER_EXPORT___;
