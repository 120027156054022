// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cjlpzKGkuguxlxRz0fqD{max-height:0px;overflow:hidden}.CXU8vKO0BclW2sROtNx6{max-height:100px;transition:max-height 500ms ease-in-out}.WjhQkPusUU6iPCKVRFGd,.Swf351f4KawWVZcWDZ_k{max-height:100px;overflow:hidden;transition:max-height .4s .2s ease-in-out}.DuFDPSjasrdi6KIleNcU,.PX2RLml2GBWklc_A_RZQ{max-height:0}", "",{"version":3,"sources":["webpack://./../next-react/src/components/atoms/AnimatedList/styles.scss"],"names":[],"mappings":"AAEE,sBACE,cAAA,CACA,eAAA,CAGF,sBACE,gBAAA,CACA,uCAAA,CAGF,4CAEE,gBAAA,CACA,eAAA,CACA,yCAAA,CAGF,4CAEE,YAAA","sourcesContent":["$theme: next;\n.transition {\n  &Enter {\n    max-height: 0px;\n    overflow: hidden;\n  }\n\n  &EnterActive {\n    max-height: 100px;\n    transition: max-height 500ms ease-in-out;\n  }\n\n  &Leave,\n  &Exit {\n    max-height: 100px;\n    overflow: hidden;\n    transition: max-height 0.4s 0.2s ease-in-out;\n  }\n\n  &LeaveActive,\n  &ExitActive {\n    max-height: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transitionEnter": "cjlpzKGkuguxlxRz0fqD",
	"transitionEnterActive": "CXU8vKO0BclW2sROtNx6",
	"transitionLeave": "WjhQkPusUU6iPCKVRFGd",
	"transitionExit": "Swf351f4KawWVZcWDZ_k",
	"transitionLeaveActive": "DuFDPSjasrdi6KIleNcU",
	"transitionExitActive": "PX2RLml2GBWklc_A_RZQ"
};
export default ___CSS_LOADER_EXPORT___;
