// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eNz1TzImRAUQ3Qzk2lC8{padding-left:10px;font-size:80%;opacity:.7}", "",{"version":3,"sources":["webpack://./../next-react/src/components/atoms/NextScopesTable/NextScopesTable.scss"],"names":[],"mappings":"AAEE,sBACE,iBAAA,CACA,aAAA,CACA,UAAA","sourcesContent":["$theme: next;\n.NextScopesTable {\n  &_youIndicator {\n    padding-left: 10px;\n    font-size: 80%;\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NextScopesTable_youIndicator": "eNz1TzImRAUQ3Qzk2lC8"
};
export default ___CSS_LOADER_EXPORT___;
