// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CraEUvo3fV7Sl3bmrTm5{display:flex}.TWAcJtwFK6uuH2v2BC_P{flex-grow:1}", "",{"version":3,"sources":["webpack://./../next-react/src/components/views/CourseArticleView/styles.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CAEA,sBACE,WAAA","sourcesContent":["$theme: next;\n.CourseArticleView {\n  display: flex;\n\n  &_article {\n    flex-grow: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CourseArticleView": "CraEUvo3fV7Sl3bmrTm5",
	"CourseArticleView_article": "TWAcJtwFK6uuH2v2BC_P"
};
export default ___CSS_LOADER_EXPORT___;
